export const IconPlus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4" width="16" height="16">
    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>

)

export const IconTP = () => (
  <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.03503 2.29994C1.43503 0.569941 4.04003 0.569941 4.43503 2.29994C4.67003 3.31494 4.02503 4.17494 3.46503 4.70994C3.05503 5.09994 2.41003 5.09494 2.00003 4.70994C1.44503 4.17494 0.800028 3.31494 1.03503 2.29994Z" stroke="black"/>
<path d="M7.535 8.29994C7.935 6.56994 10.555 6.56994 10.955 8.29994C11.19 9.31494 10.545 10.1749 9.98 10.7099C9.57 11.0999 8.92 11.0949 8.51 10.7099C7.945 10.1749 7.3 9.31494 7.535 8.29994Z" stroke="black"/>
<path d="M6.00009 2.5H7.34009C8.26509 2.5 8.69509 3.645 8.00009 4.255L4.00509 7.75C3.31009 8.355 3.74009 9.5 4.66009 9.5H6.00009" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.74311 2.75H2.74889" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.24311 8.75H9.24888" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)

export const IconCar = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.2667 15.1667H4.73342C3.60009 15.1667 2.66675 14.24 2.66675 13.1V3.90004C2.66675 2.76671 3.59342 1.83337 4.73342 1.83337H11.2667C12.4001 1.83337 13.3334 2.76004 13.3334 3.90004V13.1C13.3334 14.24 12.4067 15.1667 11.2667 15.1667Z" stroke="#006D38" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3334 9.16671H3.66675C3.11341 9.16671 2.66675 8.72004 2.66675 8.16671V6.83337C2.66675 6.28004 3.11341 5.83337 3.66675 5.83337H12.3334C12.8867 5.83337 13.3334 6.28004 13.3334 6.83337V8.16671C13.3334 8.72004 12.8867 9.16671 12.3334 9.16671Z" stroke="#006D38" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.66292 12.3H5.66891" stroke="#006D38" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.3297 12.3H10.3357" stroke="#006D38" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.33325 3.83337H9.66659" stroke="#006D38" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)

export const IconTicket = () => (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.75 17.5H3.25C2.58696 17.5 1.95107 17.2366 1.48223 16.7678C1.01339 16.2989 0.75 15.663 0.75 15V13.8481C0.75 13.4445 1.07404 13.1225 1.4631 13.0153C1.99953 12.8675 2.49108 12.5774 2.8825 12.1697C3.44236 11.5865 3.75346 10.8084 3.75 10C3.75315 9.19167 3.44195 8.41377 2.88215 7.83064C2.49073 7.42291 1.99921 7.13274 1.4628 6.98487C1.07386 6.87765 0.75 6.55563 0.75 6.15219L0.75 5C0.75 4.33696 1.01339 3.70107 1.48223 3.23223C1.95107 2.76339 2.58696 2.5 3.25 2.5H16.75C17.413 2.5 18.0489 2.76339 18.5178 3.23223C18.9866 3.70107 19.25 4.33696 19.25 5V6.125C19.25 6.53921 18.9095 6.86609 18.5072 6.96456C17.9564 7.09936 17.4479 7.38272 17.0403 7.79029C16.4542 8.37634 16.125 9.1712 16.125 10C16.125 10.8288 16.4542 11.6237 17.0403 12.2097C17.4479 12.6173 17.9564 12.9006 18.5072 13.0354C18.9095 13.1339 19.25 13.4608 19.25 13.875V15C19.25 15.663 18.9866 16.2989 18.5178 16.7678C18.0489 17.2366 17.413 17.5 16.75 17.5Z" fill="white" stroke="#006D38" stroke-width="1.5"/>
<line x1="7.16602" y1="4.16675" x2="7.16602" y2="15.8334" stroke="#006D38" stroke-dasharray="3 3"/>
</svg>
)

export const IconMoney = () => (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.22656 11.9415C7.22656 13.0165 8.05156 13.8831 9.07656 13.8831H11.1682C12.0599 13.8831 12.7849 13.1248 12.7849 12.1915C12.7849 11.1748 12.3432 10.8165 11.6849 10.5831L8.32656 9.41645C7.66823 9.18312 7.22656 8.82479 7.22656 7.80812C7.22656 6.87479 7.95156 6.11646 8.84323 6.11646H10.9349C11.9599 6.11646 12.7849 6.98312 12.7849 8.05812" stroke="#006D38" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 5V15" stroke="#006D38" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.99935 18.3334C14.6017 18.3334 18.3327 14.6025 18.3327 10.0001C18.3327 5.39771 14.6017 1.66675 9.99935 1.66675C5.39698 1.66675 1.66602 5.39771 1.66602 10.0001C1.66602 14.6025 5.39698 18.3334 9.99935 18.3334Z" stroke="#006D38" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)

export const BluePoint = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.0002 25.6667C20.4168 25.6667 25.6668 20.4167 25.6668 14C25.6668 7.58337 20.4168 2.33337 14.0002 2.33337C7.5835 2.33337 2.3335 7.58337 2.3335 14C2.3335 20.4167 7.5835 25.6667 14.0002 25.6667Z" fill="#2D80E9" stroke="#2D80E9" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M15.7502 9.33337C12.2152 9.33337 9.3335 12.2267 9.3335 15.75C9.3335 17.3484 10.6402 18.6667 12.2502 18.6667C15.7735 18.6667 18.6668 15.7734 18.6668 12.25C18.6668 10.6517 17.3485 9.33337 15.7502 9.33337Z" fill="white"/>
</svg>

)

export const MiniBlue = () => (
  <svg width="16" height="16" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.0002 25.6667C20.4168 25.6667 25.6668 20.4167 25.6668 14C25.6668 7.58337 20.4168 2.33337 14.0002 2.33337C7.5835 2.33337 2.3335 7.58337 2.3335 14C2.3335 20.4167 7.5835 25.6667 14.0002 25.6667Z" fill="#2D80E9" stroke="#2D80E9" stroke-width="1.5" stroke-miterlimit="10"/>
  <path d="M15.7502 9.33337C12.2152 9.33337 9.3335 12.2267 9.3335 15.75C9.3335 17.3484 10.6402 18.6667 12.2502 18.6667C15.7735 18.6667 18.6668 15.7734 18.6668 12.25C18.6668 10.6517 17.3485 9.33337 15.7502 9.33337Z" fill="white"/>
  </svg>
)

export const MiniRed = () => (
  <svg width="16" height="16" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.72316 9.90504C7.02149 -0.19829 21.9898 -0.186623 24.2765 9.91671C25.6182 15.8434 21.9315 20.86 18.6998 23.9634C16.3548 26.2267 12.6448 26.2267 10.2882 23.9634C7.06816 20.86 3.38149 15.8317 4.72316 9.90504Z" fill="#FF3333" stroke="#FF3333" stroke-width="1.5"/>
  <path d="M14.4999 15.6683C16.5102 15.6683 18.1399 14.0386 18.1399 12.0283C18.1399 10.018 16.5102 8.38831 14.4999 8.38831C12.4895 8.38831 10.8599 10.018 10.8599 12.0283C10.8599 14.0386 12.4895 15.6683 14.4999 15.6683Z" fill="white" stroke="white" stroke-width="1.5"/>
  </svg>
)
export const MiniPhone = () => (
  <svg width="12" height="12" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.9732 16.3296C19.9732 16.6896 19.8932 17.0596 19.7232 17.4196C19.5532 17.7796 19.3332 18.1196 19.0432 18.4396C18.5532 18.9796 18.0132 19.3696 17.4032 19.6196C16.8032 19.8696 16.1532 19.9996 15.4532 19.9996C14.4332 19.9996 13.3432 19.7596 12.1932 19.2696C11.0432 18.7796 9.89317 18.1196 8.75317 17.2896C7.60317 16.4496 6.51317 15.5196 5.47317 14.4896C4.44317 13.4496 3.51317 12.3596 2.68317 11.2196C1.86317 10.0796 1.20317 8.93963 0.723174 7.80963C0.243174 6.66963 0.00317383 5.57963 0.00317383 4.53963C0.00317383 3.85963 0.123174 3.20963 0.363174 2.60963C0.603174 1.99963 0.983174 1.43963 1.51317 0.939634C2.15317 0.309634 2.85317 -0.000366211 3.59317 -0.000366211C3.87317 -0.000366211 4.15317 0.0596339 4.40317 0.179634C4.66317 0.299634 4.89317 0.479634 5.07317 0.739634L7.39317 4.00963C7.57317 4.25963 7.70317 4.48963 7.79317 4.70963C7.88317 4.91963 7.93317 5.12963 7.93317 5.31963C7.93317 5.55963 7.86317 5.79963 7.72317 6.02963C7.59317 6.25963 7.40317 6.49963 7.16317 6.73963L6.40317 7.52963C6.29317 7.63963 6.24317 7.76963 6.24317 7.92963C6.24317 8.00963 6.25317 8.07963 6.27317 8.15963C6.30317 8.23963 6.33317 8.29963 6.35317 8.35963C6.53317 8.68963 6.84317 9.11963 7.28317 9.63963C7.73317 10.1596 8.21317 10.6896 8.73317 11.2196C9.27317 11.7496 9.79317 12.2396 10.3232 12.6896C10.8432 13.1296 11.2732 13.4296 11.6132 13.6096C11.6632 13.6296 11.7232 13.6596 11.7932 13.6896C11.8732 13.7196 11.9532 13.7296 12.0432 13.7296C12.2132 13.7296 12.3432 13.6696 12.4532 13.5596L13.2132 12.8096C13.4632 12.5596 13.7032 12.3696 13.9332 12.2496C14.1632 12.1096 14.3932 12.0396 14.6432 12.0396C14.8332 12.0396 15.0332 12.0796 15.2532 12.1696C15.4732 12.2596 15.7032 12.3896 15.9532 12.5596L19.2632 14.9096C19.5232 15.0896 19.7032 15.2996 19.8132 15.5496C19.9132 15.7996 19.9732 16.0496 19.9732 16.3296Z" fill="#006D38"/>
  </svg>
)

export const MiniMap = () => (
  <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.1499 7.48999C8.5899 7.48999 8.1499 7.93999 8.1499 8.48999C8.1499 9.03999 8.5999 9.48999 9.1499 9.48999C9.6999 9.48999 10.1499 9.03999 10.1499 8.48999C10.1499 7.93999 9.6999 7.48999 9.1499 7.48999Z" fill="#006D38"/>
<path d="M21.46 5.04C20.62 3.09 18.77 2 16.19 2H7.81C4.6 2 2 4.6 2 7.81V16.19C2 18.77 3.09 20.62 5.04 21.46C5.23 21.54 5.45 21.49 5.59 21.35L21.35 5.59C21.5 5.44 21.55 5.22 21.46 5.04ZM10.53 12.24C10.14 12.62 9.63 12.8 9.12 12.8C8.61 12.8 8.1 12.61 7.71 12.24C6.69 11.28 5.57 9.75 6 7.93C6.38 6.28 7.84 5.54 9.12 5.54C10.4 5.54 11.86 6.28 12.24 7.94C12.66 9.75 11.54 11.28 10.53 12.24Z" fill="#006D38"/>
<path d="M19.4699 20.53C19.6899 20.75 19.6599 21.11 19.3899 21.26C18.5099 21.75 17.4399 22 16.1899 22H7.8099C7.5199 22 7.3999 21.66 7.5999 21.46L13.6399 15.42C13.8399 15.22 14.1499 15.22 14.3499 15.42L19.4699 20.53Z" fill="#006D38"/>
<path d="M22 7.81002V16.19C22 17.44 21.75 18.52 21.26 19.39C21.11 19.66 20.75 19.68 20.53 19.47L15.41 14.35C15.21 14.15 15.21 13.84 15.41 13.64L21.45 7.60002C21.66 7.40002 22 7.52002 22 7.81002Z" fill="#006D38"/>
</svg>
)

export const MiniLocation = () => (
<svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z" fill="#006D38" stroke="#006D38" stroke-width="1.5"/>
<path d="M11.9999 13.43C13.723 13.43 15.1199 12.0331 15.1199 10.31C15.1199 8.58687 13.723 7.19 11.9999 7.19C10.2768 7.19 8.87988 8.58687 8.87988 10.31C8.87988 12.0331 10.2768 13.43 11.9999 13.43Z" fill="white" stroke="white" stroke-width="1.5"/>
</svg>
)

export const Location = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z" fill="#006D38" stroke="#006D38" stroke-width="1.5"/>
  <path d="M11.9999 13.43C13.723 13.43 15.1199 12.0331 15.1199 10.31C15.1199 8.58687 13.723 7.19 11.9999 7.19C10.2768 7.19 8.87988 8.58687 8.87988 10.31C8.87988 12.0331 10.2768 13.43 11.9999 13.43Z" fill="white" stroke="white" stroke-width="1.5"/>
  </svg>
  )
  
  export const Map = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.1499 7.48999C8.5899 7.48999 8.1499 7.93999 8.1499 8.48999C8.1499 9.03999 8.5999 9.48999 9.1499 9.48999C9.6999 9.48999 10.1499 9.03999 10.1499 8.48999C10.1499 7.93999 9.6999 7.48999 9.1499 7.48999Z" fill="#006D38"/>
  <path d="M21.46 5.04C20.62 3.09 18.77 2 16.19 2H7.81C4.6 2 2 4.6 2 7.81V16.19C2 18.77 3.09 20.62 5.04 21.46C5.23 21.54 5.45 21.49 5.59 21.35L21.35 5.59C21.5 5.44 21.55 5.22 21.46 5.04ZM10.53 12.24C10.14 12.62 9.63 12.8 9.12 12.8C8.61 12.8 8.1 12.61 7.71 12.24C6.69 11.28 5.57 9.75 6 7.93C6.38 6.28 7.84 5.54 9.12 5.54C10.4 5.54 11.86 6.28 12.24 7.94C12.66 9.75 11.54 11.28 10.53 12.24Z" fill="#006D38"/>
  <path d="M19.4699 20.53C19.6899 20.75 19.6599 21.11 19.3899 21.26C18.5099 21.75 17.4399 22 16.1899 22H7.8099C7.5199 22 7.3999 21.66 7.5999 21.46L13.6399 15.42C13.8399 15.22 14.1499 15.22 14.3499 15.42L19.4699 20.53Z" fill="#006D38"/>
  <path d="M22 7.81002V16.19C22 17.44 21.75 18.52 21.26 19.39C21.11 19.66 20.75 19.68 20.53 19.47L15.41 14.35C15.21 14.15 15.21 13.84 15.41 13.64L21.45 7.60002C21.66 7.40002 22 7.52002 22 7.81002Z" fill="#006D38"/>
  </svg>
  
  
  )
  
  export const Phone = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.9732 16.3296C19.9732 16.6896 19.8932 17.0596 19.7232 17.4196C19.5532 17.7796 19.3332 18.1196 19.0432 18.4396C18.5532 18.9796 18.0132 19.3696 17.4032 19.6196C16.8032 19.8696 16.1532 19.9996 15.4532 19.9996C14.4332 19.9996 13.3432 19.7596 12.1932 19.2696C11.0432 18.7796 9.89317 18.1196 8.75317 17.2896C7.60317 16.4496 6.51317 15.5196 5.47317 14.4896C4.44317 13.4496 3.51317 12.3596 2.68317 11.2196C1.86317 10.0796 1.20317 8.93963 0.723174 7.80963C0.243174 6.66963 0.00317383 5.57963 0.00317383 4.53963C0.00317383 3.85963 0.123174 3.20963 0.363174 2.60963C0.603174 1.99963 0.983174 1.43963 1.51317 0.939634C2.15317 0.309634 2.85317 -0.000366211 3.59317 -0.000366211C3.87317 -0.000366211 4.15317 0.0596339 4.40317 0.179634C4.66317 0.299634 4.89317 0.479634 5.07317 0.739634L7.39317 4.00963C7.57317 4.25963 7.70317 4.48963 7.79317 4.70963C7.88317 4.91963 7.93317 5.12963 7.93317 5.31963C7.93317 5.55963 7.86317 5.79963 7.72317 6.02963C7.59317 6.25963 7.40317 6.49963 7.16317 6.73963L6.40317 7.52963C6.29317 7.63963 6.24317 7.76963 6.24317 7.92963C6.24317 8.00963 6.25317 8.07963 6.27317 8.15963C6.30317 8.23963 6.33317 8.29963 6.35317 8.35963C6.53317 8.68963 6.84317 9.11963 7.28317 9.63963C7.73317 10.1596 8.21317 10.6896 8.73317 11.2196C9.27317 11.7496 9.79317 12.2396 10.3232 12.6896C10.8432 13.1296 11.2732 13.4296 11.6132 13.6096C11.6632 13.6296 11.7232 13.6596 11.7932 13.6896C11.8732 13.7196 11.9532 13.7296 12.0432 13.7296C12.2132 13.7296 12.3432 13.6696 12.4532 13.5596L13.2132 12.8096C13.4632 12.5596 13.7032 12.3696 13.9332 12.2496C14.1632 12.1096 14.3932 12.0396 14.6432 12.0396C14.8332 12.0396 15.0332 12.0796 15.2532 12.1696C15.4732 12.2596 15.7032 12.3896 15.9532 12.5596L19.2632 14.9096C19.5232 15.0896 19.7032 15.2996 19.8132 15.5496C19.9132 15.7996 19.9732 16.0496 19.9732 16.3296Z" fill="#006D38"/>
  </svg>
  
  )